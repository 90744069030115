// src/vanilla/internal/symbols.ts
var TypeSymbol = Symbol.for("bunshi.molecules.type");
var ScopeSymbol = Symbol.for("bunshi.scope.type");
var Injector = Symbol.for("bunshi.injector.instance");
var GetterSymbol = Symbol.for("bunshi.molecules.getter");
var MoleculeSymbol = Symbol.for("bunshi.molecules.molecule");
var MoleculeInterfaceSymbol = Symbol.for(
  "bunshi.molecules.molecule.interface"
);
var DefaultInjector = Symbol.for(
  "bunshi.injector.defaultGlobalInjector"
);
var Debug = Symbol("bunshi.debug");
var SortId = Symbol("bunshi.scope.sort");

// src/vanilla/scope.ts
function createScope(defaultValue, options) {
  const sortId = debugId++;
  let staticDefaultTupleValue;
  const debugValue = Symbol((options == null ? void 0 : options.debugLabel) ?? `bunshi.scope ${sortId}`);
  return {
    defaultValue,
    [TypeSymbol]: ScopeSymbol,
    [Debug]: debugValue,
    [SortId]: sortId,
    get defaultTuple() {
      if (staticDefaultTupleValue)
        return staticDefaultTupleValue;
      staticDefaultTupleValue = [this, defaultValue];
      return staticDefaultTupleValue;
    }
  };
}
var debugId = 1;

// src/vanilla/ComponentScope.ts
var ComponentScope = createScope(void 0, {
  debugLabel: "Component Scope"
});

// src/vanilla/instanceIds.ts
var instCount = 0;
function instanceId() {
  return Symbol(`bunshi.instance ${instCount++}`);
}

// src/vanilla/internal/errors.ts
var ErrorAsyncGetMol = "`mol` called asynchronously in a molecule constructor. Make sure your calls are only synchronous.";
var ErrorAsyncGetScope = "`scope` called asynchronously in a molecule constructor. Make sure your calls are only synchronous.";
var ErrorUnboundMolecule = `Unbound molecule interface. Could not find a molecule.`;
var ErrorInvalidScope = "`scope` called with an object that is not a MoleculeScope";
var ErrorInvalidMolecule = "`mol` called with an object that is not a Molecule or Interface";
var ErrorInvalidGlobalInjector = "Global namespace conflict. Default injector is not a bunshi injector.";
var ErrorBadUse = "Can only call `use` with a molecule, interface or scope";

// src/vanilla/internal/scopeTupleSort.ts
function scopeTupleSort(arr) {
  return [...arr].sort((a, b) => compareScopes(a[0], b[0]));
}
function compareScopes(a, b) {
  return a[SortId] - b[SortId];
}

// src/vanilla/internal/utils.ts
function __isInternalType(value, typeSymbol) {
  if (!value)
    return false;
  if (typeof value !== "object")
    return false;
  return value[TypeSymbol] === typeSymbol;
}
function isMolecule(value) {
  return __isInternalType(value, MoleculeSymbol);
}
function isMoleculeScope(value) {
  return __isInternalType(value, ScopeSymbol);
}
function isMoleculeInterface(value) {
  return __isInternalType(value, MoleculeInterfaceSymbol);
}
function isInjector(value) {
  return __isInternalType(value, Injector);
}

// src/vanilla/internal/weakCache.ts
var getWeakCacheItem = (cache, deps) => {
  while (true) {
    const [dep, ...rest] = deps;
    const entry = cache.get(dep);
    if (!entry) {
      return;
    }
    if (!rest.length) {
      return entry[1];
    }
    cache = entry[0];
    deps = rest;
  }
};
var setWeakCacheItem = (cache, deps, item) => {
  while (true) {
    const [dep, ...rest] = deps;
    let entry = cache.get(dep);
    if (!entry) {
      entry = [/* @__PURE__ */ new WeakMap()];
      cache.set(dep, entry);
    }
    if (!rest.length) {
      entry[1] = item;
      return;
    }
    cache = entry[0];
    deps = rest;
  }
};
var createDeepCache = () => {
  let cache = /* @__PURE__ */ new WeakMap();
  const deepCache = (createFn, foundFn, deps) => {
    if (!deps.length)
      throw new Error("Dependencies need to exist.");
    const cachedValue = getWeakCacheItem(cache, deps);
    if (cachedValue) {
      foundFn(cachedValue);
      return cachedValue;
    }
    const newObject = createFn();
    setWeakCacheItem(cache, deps, newObject);
    return newObject;
  };
  const upsert = (createFn, deps) => {
    if (!deps.length)
      throw new Error("Dependencies need to exist.");
    const cachedValue = getWeakCacheItem(cache, deps);
    const newObject = createFn(cachedValue);
    setWeakCacheItem(cache, deps, newObject);
  };
  const remove = (...deps) => {
    removeWeakCacheItem(cache, deps);
  };
  const get = (deps) => getWeakCacheItem(cache, deps);
  return {
    get,
    cache,
    deepCache,
    upsert,
    remove
  };
};
var removeWeakCacheItem = (cache, deps) => {
  while (true) {
    const [dep, ...rest] = deps;
    let entry = cache.get(dep);
    if (!entry) {
      return;
    }
    const isBottom = !rest.length;
    if (isBottom) {
      entry[1] = void 0;
      return;
    } else {
      cache = entry[0];
      deps = rest;
    }
  }
};

// src/vanilla/lifecycle.ts
var GlobalFunctionImplementation = class {
  /**
   * This is structured as a stack to support nested
   * molecule call structures
   */
  _s = [];
  push = (x) => {
    this._s.push(x);
  };
  pop = () => {
    this._s.pop();
  };
  active = (publicApi) => {
    const top = this._s[this._s.length - 1];
    if (!top)
      throw new Error(
        `Cannot call \`${publicApi}\` outside of a molecule function`
      );
    return top;
  };
};
var onMountImpl = new GlobalFunctionImplementation();
var useImpl = new GlobalFunctionImplementation();
function onMount(fn) {
  onMountImpl.active("onMount")(fn);
}
function onUnmount(fn) {
  onMountImpl.active("onUnmount")(() => fn);
}
function use(dependency) {
  return useImpl.active("use")(dependency);
}

// src/vanilla/scoper.ts
function createScoper(instrumentation) {
  const scopeCache = /* @__PURE__ */ new WeakMap();
  const cleanupsRun = /* @__PURE__ */ new WeakSet();
  const releasedSubscriptions = /* @__PURE__ */ new WeakSet();
  function getScopes(tuples) {
    return tuples.map((t) => getScope(t));
  }
  function getScope(tuple) {
    var _a;
    const [scope, value] = tuple;
    const cached = (_a = scopeCache.get(scope)) == null ? void 0 : _a.get(value);
    if (cached) {
      return cached.tuple;
    }
    return tuple;
  }
  function startSubscription(subscriptionObj, tuple) {
    var _a;
    const [scope, value] = tuple;
    const innerCached = (_a = scopeCache.get(scope)) == null ? void 0 : _a.get(value);
    if (innerCached) {
      innerCached.references.add(subscriptionObj);
      return innerCached.tuple;
    } else {
      const valuesForScope = scopeCache.get(scope) ?? scopeCache.set(scope, /* @__PURE__ */ new Map()).get(scope);
      valuesForScope.set(value, {
        tuple,
        references: /* @__PURE__ */ new Set([subscriptionObj]),
        cleanups: /* @__PURE__ */ new Set()
      });
      return tuple;
    }
  }
  function startSubscriptions(subscriptionObj, tuples) {
    return tuples.map((t) => startSubscription(subscriptionObj, t));
  }
  function stopSubscription(tuples, subscriptionObj) {
    if (releasedSubscriptions.has(subscriptionObj)) {
      return;
    } else {
      releasedSubscriptions.add(subscriptionObj);
    }
    if (!tuples)
      return;
    const cleanupsToRun = releaseTuples(tuples, subscriptionObj);
    Array.from(cleanupsToRun.values()).reverse().forEach((cb) => {
      if (!cleanupsRun.has(cb)) {
        instrumentation == null ? void 0 : instrumentation.scopeRunCleanup(cb);
        cb();
        cleanupsRun.add(cb);
      }
    });
  }
  function releaseTuples(tuples, subscriptionObj) {
    const cleanupsToRun = /* @__PURE__ */ new Set();
    tuples.forEach(([scope, value]) => {
      const scopeMap = scopeCache.get(scope);
      const cached = scopeMap == null ? void 0 : scopeMap.get(value);
      const references = cached == null ? void 0 : cached.references;
      references == null ? void 0 : references.delete(subscriptionObj);
      if (references && references.size <= 0) {
        instrumentation == null ? void 0 : instrumentation.scopeStopWithCleanup(subscriptionObj, cached);
        scopeMap == null ? void 0 : scopeMap.delete(value);
        cached == null ? void 0 : cached.cleanups.forEach((cb) => {
          cleanupsToRun.add(cb);
        });
      } else {
        instrumentation == null ? void 0 : instrumentation.scopeStopWithCleanup(subscriptionObj, cached);
      }
    });
    return cleanupsToRun;
  }
  function registerCleanups(scopeKeys, cleanupSet) {
    scopeKeys.forEach(([scopeKey, scopeValue]) => {
      cleanupSet.forEach((cleanup) => {
        var _a, _b;
        const cleanups = (_b = (_a = scopeCache.get(scopeKey)) == null ? void 0 : _a.get(scopeValue)) == null ? void 0 : _b.cleanups;
        if (!cleanups) {
          throw new Error("Can't register cleanups for uncached values");
        }
        cleanups.add(cleanup);
      });
    });
  }
  function useScopes(...scopes) {
    const subscription = createSubscription();
    subscription.expand(scopes);
    subscription.start();
    return [subscription.tuples, () => subscription.stop()];
  }
  function createSubscription() {
    let internal = new ScopeSubscriptionImpl();
    let stopped = false;
    function restart() {
      const previousTuples = internal.tuples;
      internal = new ScopeSubscriptionImpl();
      internal.expand(previousTuples);
      return internal.start();
    }
    return {
      addCleanups(cleanups) {
        registerCleanups(this.tuples, cleanups);
      },
      get tuples() {
        return internal.tuples;
      },
      expand(next) {
        return internal.expand(next);
      },
      start() {
        if (stopped) {
          stopped = false;
          return restart();
        }
        return internal.start();
      },
      stop() {
        internal.stop();
        stopped = true;
      }
    };
  }
  class ScopeSubscriptionImpl {
    addCleanups(cleanups) {
      registerCleanups(this.tuples, cleanups);
    }
    __tupleMap = /* @__PURE__ */ new Map();
    __stableArray = [];
    get tuples() {
      return this.__stableArray;
    }
    expand(next) {
      const tuples = getScopes(next);
      tuples.forEach((t) => {
        this.__tupleMap.set(t[0], t);
      });
      this.__stableArray = Array.from(this.__tupleMap.values());
      return tuples;
    }
    start() {
      return startSubscriptions(this, this.__stableArray);
    }
    stop() {
      stopSubscription(new Set(this.tuples), this);
    }
  }
  return {
    useScopes,
    registerCleanups,
    createSubscription
  };
}

// src/vanilla/injector.ts
var InternalOnlyGlobalScope = createScope(
  Symbol("bunshi.global.scope.value"),
  { debugLabel: "Global Scope" }
);
function bindingsToMap(bindings) {
  if (!bindings)
    return /* @__PURE__ */ new Map();
  if (Array.isArray(bindings)) {
    return new Map(bindings);
  }
  return new Map(bindings.entries());
}
function createInjector(injectorProps = {}) {
  const moleculeCache = createDeepCache();
  const dependencyCache = /* @__PURE__ */ new WeakMap();
  const bindings = bindingsToMap(injectorProps.bindings);
  const scoper = createScoper(injectorProps.instrumentation);
  function getTrueMolecule(molOrIntf) {
    const bound = bindings.get(molOrIntf);
    if (bound)
      return bound;
    if (isMolecule(molOrIntf))
      return molOrIntf;
    throw new Error(ErrorUnboundMolecule);
  }
  function getInternal(m, props) {
    var _a, _b, _c;
    (_a = injectorProps.instrumentation) == null ? void 0 : _a.getInternal(m);
    const cachedDeps = dependencyCache.get(m);
    if (cachedDeps) {
      const relevantScopes = props.scopes.filter(
        (tuple) => cachedDeps.has(tuple[0])
      );
      const deps = getCachePath(relevantScopes, m);
      const cachedValue = moleculeCache.get(deps);
      if (cachedValue) {
        cachedValue.deps.defaultScopes.forEach((s) => {
          props.lease(s.defaultTuple);
        });
        (_b = injectorProps.instrumentation) == null ? void 0 : _b.stage1CacheHit(m, cachedValue);
        return cachedValue;
      } else {
      }
    }
    (_c = injectorProps.instrumentation) == null ? void 0 : _c.stage1CacheMiss();
    const { previous } = props;
    if (previous !== false) {
      return moleculeCache.deepCache(
        () => previous,
        () => {
        },
        previous.path
      );
    }
    return runAndCache(m, props);
  }
  function multiCache(mol, scopes, createFn, foundFn) {
    const deps = getCachePath(scopes, mol);
    const cached = moleculeCache.deepCache(
      () => {
        const innerCached = {
          ...createFn(),
          path: deps,
          instanceId: instanceId()
        };
        return innerCached;
      },
      foundFn,
      deps
    );
    return cached;
  }
  function runAndCache(m, props) {
    var _a;
    const getScopeValue = (scope) => {
      const defaultScopes = /* @__PURE__ */ new Set();
      const found = props.scopes.find(([key]) => key === scope);
      if (found) {
        const isDefaultValue = found[1] === found[0].defaultValue;
        if (!isDefaultValue) {
          return {
            value: found[1],
            defaultScopes
          };
        } else {
        }
      }
      defaultScopes.add(scope);
      return {
        value: scope.defaultValue,
        defaultScopes
      };
    };
    const mounted = runMolecule(
      m,
      getScopeValue,
      (m2) => getInternal(m2, props),
      getTrueMolecule
    );
    (_a = injectorProps.instrumentation) == null ? void 0 : _a.executed(m, mounted);
    const relatedScope = props.scopes.filter(
      ([key]) => mounted.deps.allScopes.has(key)
    );
    if (dependencyCache.has(m)) {
      const cachedDeps = dependencyCache.get(m);
      if (mounted.deps.allScopes.size !== (cachedDeps == null ? void 0 : cachedDeps.size)) {
        throw new Error(
          "Molecule is using conditional dependencies. This is not supported."
        );
      }
      let mismatch = false;
      mounted.deps.allScopes.forEach((s) => {
        if (!cachedDeps.has(s)) {
          mismatch = true;
        }
      });
      if (mismatch) {
        throw new Error(
          "Molecule is using conditional dependencies. This is not supported."
        );
      }
    } else {
      dependencyCache.set(m, mounted.deps.allScopes);
    }
    return multiCache(
      m,
      relatedScope,
      () => {
        var _a2;
        mounted.deps.defaultScopes.forEach((s) => {
          props.lease(s.defaultTuple);
        });
        const created = {
          deps: mounted.deps,
          value: mounted.value,
          isMounted: false
        };
        (_a2 = injectorProps.instrumentation) == null ? void 0 : _a2.stage2CacheMiss(created);
        return created;
      },
      (found) => {
        var _a2;
        found.deps.defaultScopes.forEach((s) => {
          props.lease(s.defaultTuple);
        });
        (_a2 = injectorProps.instrumentation) == null ? void 0 : _a2.stage2CacheHit(m, found);
      }
    );
  }
  function runMount(mol) {
    var _a;
    if (mol.isMounted) {
      return mol;
    }
    mol.isMounted = true;
    mol.deps.buddies.forEach(runMount);
    const cleanupSet = /* @__PURE__ */ new Set();
    mol.deps.mountedCallbacks.forEach((onMount2) => {
      const cleanup = onMount2();
      if (cleanup) {
        cleanupSet.add(cleanup);
      }
    });
    cleanupSet.add(function moleculeCacheCleanup() {
      var _a2;
      (_a2 = injectorProps.instrumentation) == null ? void 0 : _a2.cleanup(mol);
      moleculeCache.remove(...mol.path);
      mol.isMounted = false;
    });
    const usedDefaultScopes = Array.from(mol.deps.defaultScopes.values()).map(
      (s) => s.defaultTuple
    );
    scoper.registerCleanups(usedDefaultScopes, cleanupSet);
    const usedScopes = mol.path.filter(
      (molOrScope) => Array.isArray(molOrScope)
    );
    scoper.registerCleanups(usedScopes, cleanupSet);
    (_a = injectorProps == null ? void 0 : injectorProps.instrumentation) == null ? void 0 : _a.mounted(mol, usedScopes, cleanupSet);
    return mol;
  }
  function get(m, ...scopes) {
    const [value, unsub] = use2(m, ...scopes);
    return value;
  }
  function use2(m, ...scopes) {
    const [moleculeValue, options] = lazyUse(m, ...scopes);
    return [options.start(), options.stop];
  }
  function lazyUse(m, ...scopes) {
    if (!isMolecule(m) && !isMoleculeInterface(m))
      throw new Error(ErrorInvalidMolecule);
    const sub = scoper.createSubscription();
    const tuples = sub.expand(scopes);
    const bound = getTrueMolecule(m);
    let state = 0 /* INITIAL */;
    const lease = (tuple) => {
      const [memoized] = sub.expand([tuple]);
      return memoized;
    };
    let cacheValue = getInternal(bound, {
      scopes: tuples,
      lease,
      previous: false
    });
    const start = () => {
      var _a;
      if (state === 1 /* ACTIVE */) {
        throw new Error("Don't start a subscription that is already started.");
      }
      (_a = injectorProps == null ? void 0 : injectorProps.instrumentation) == null ? void 0 : _a.subscribe(bound, cacheValue);
      cacheValue = getInternal(bound, {
        scopes: sub.start(),
        lease,
        previous: cacheValue
      });
      runMount(cacheValue);
      state = 1 /* ACTIVE */;
      return cacheValue.value;
    };
    const stop = () => {
      var _a;
      if (state === 2 /* STOPPED */) {
        throw new Error("Don't start a subscription that is already started.");
      }
      (_a = injectorProps == null ? void 0 : injectorProps.instrumentation) == null ? void 0 : _a.unsubscribe(bound, cacheValue);
      sub.stop();
      state = 2 /* STOPPED */;
    };
    return [cacheValue.value, { start, stop }];
  }
  return {
    [TypeSymbol]: Injector,
    get,
    use: use2,
    useLazily: lazyUse,
    useScopes: scoper.useScopes,
    createSubscription: scoper.createSubscription
  };
}
function getCachePath(scopes, mol) {
  const nonDefaultScopes = scopes.filter((s) => s[0].defaultValue !== s[1]);
  const deps = [mol, ...scopeTupleSort(nonDefaultScopes)];
  return deps;
}
function runMolecule(maybeMolecule, getScopeValue, getMoleculeValue, getTrueMolecule) {
  const m = getTrueMolecule(maybeMolecule);
  const dependentMolecules = /* @__PURE__ */ new Set();
  const allScopes = /* @__PURE__ */ new Set();
  const defaultScopes = /* @__PURE__ */ new Set();
  const mountedCallbacks = /* @__PURE__ */ new Set();
  const buddies = [];
  const use2 = (dep) => {
    if (isMoleculeScope(dep)) {
      allScopes.add(dep);
      const scopeDetails = getScopeValue(dep);
      scopeDetails.defaultScopes.forEach((s) => defaultScopes.add(s));
      return scopeDetails.value;
    }
    if (isMolecule(dep) || isMoleculeInterface(dep)) {
      const dependentMolecule = getTrueMolecule(dep);
      dependentMolecules.add(dependentMolecule);
      const mol = getMoleculeValue(dependentMolecule);
      mol.deps.allScopes.forEach((s) => allScopes.add(s));
      mol.deps.defaultScopes.forEach((s) => {
        defaultScopes.add(s);
      });
      buddies.push(mol);
      return mol.value;
    }
    throw new Error(ErrorBadUse);
  };
  const trackingScopeGetter = (s) => {
    if (!running)
      throw new Error(ErrorAsyncGetScope);
    if (!isMoleculeScope(s))
      throw new Error(ErrorInvalidScope);
    return use2(s);
  };
  const trackingGetter = (molOrInterface) => {
    if (!running)
      throw new Error(ErrorAsyncGetMol);
    if (!isMolecule(molOrInterface) && !isMoleculeInterface(molOrInterface))
      throw new Error(ErrorInvalidMolecule);
    return use2(molOrInterface);
  };
  onMountImpl.push((fn) => mountedCallbacks.add(fn));
  useImpl.push(use2);
  let running = true;
  trackingScopeGetter(InternalOnlyGlobalScope);
  const value = m[GetterSymbol](trackingGetter, trackingScopeGetter);
  running = false;
  onMountImpl.pop();
  useImpl.pop();
  return {
    deps: {
      molecules: dependentMolecules,
      allScopes,
      defaultScopes,
      mountedCallbacks,
      /**
       * Returns a copy
       *
       * Reverses the order so that the deepest dependencies are at the top
       * of the list. This will be important for ensuring ordering for how
       * mounts are called with transient dependencies.
       *
       */
      buddies: buddies.slice().reverse()
    },
    value
  };
}

// src/vanilla/getDefaultInjector.ts
var getDefaultInjector = () => {
  const i = globalThis[DefaultInjector];
  if (i === void 0) {
    const n = createInjector();
    globalThis[DefaultInjector] = n;
    return n;
  }
  if (isInjector(i)) {
    return i;
  }
  throw new Error(ErrorInvalidGlobalInjector);
};
var resetDefaultInjector = (injectorProps) => {
  globalThis[DefaultInjector] = createInjector(injectorProps);
};

// src/vanilla/molecule.ts
function molecule(construct) {
  const mol = {
    [GetterSymbol]: construct,
    [TypeSymbol]: MoleculeSymbol
  };
  return mol;
}
function moleculeInterface() {
  const intf = {
    [TypeSymbol]: MoleculeInterfaceSymbol
  };
  return intf;
}

export {
  createScope,
  ComponentScope,
  onMount,
  onUnmount,
  use,
  createInjector,
  getDefaultInjector,
  resetDefaultInjector,
  molecule,
  moleculeInterface
};
