import { findAndReplace } from 'mdast-util-find-and-replace'

const RE_CHANNELS = /:([\w-]\|?)+:/g

function replacer(match) {
  const channels = match.replace(/\:/g, '').split('|')

  if (channels.length === 0) {
    return false
  }

  return {
    type: 'text',
    meta: null,
    value: match,
    data: {
      hName: 'span',
      hProperties: {
        'data-type': 'rezio-channels'
      },
      hChildren: channels.map(function (channel) {
        return { type: 'text', value: channel }
      })
    }
  }
}

export default function plugin(options) {
  function transformer(tree) {
    findAndReplace(tree, [[RE_CHANNELS, replacer]])
  }

  return transformer
}
