import { EventEmitter } from "events";
import { TwilsockClient, ConnectionState } from "twilsock";
import { Connector, ChannelType } from "./connector";
import { RegistrarConnector } from "./RegistrarConnector";
import { TwilsockConnector } from "./TwilsockConnector";
import { log } from "./logger";
import {
  validateTypesAsync,
  validateTypes,
  literal,
  nonEmptyString,
  validateConstructorTypes,
  pureObject,
} from "@twilio/declarative-type-validator";
import { LogLevelDesc } from "loglevel";

// For validating Connector.ChannelType - keep synchronized!
const channelTypeRule = literal("apn", "fcm", "twilsock");

interface PushNotification {
  messageType: string;
  payload: any;
}

interface NotificationOptions {
  region?: string;
  ersUrl?: string;
}

interface ClientOptions {
  logLevel?: LogLevelDesc;
  minTokenRefreshInterval?: number;
  productId?: string;
  twilsockClient?: TwilsockClient;
  notifications?: NotificationOptions;
  region?: string;
}

/**
 * @class
 * @alias Notifications
 * @classdesc The helper library for the notification service.
 * Provides high level api for creating and managing notification subscriptions and receiving messages
 * Creates the instance of Notification helper library
 *
 * @constructor
 * @param {string} token - Twilio access token
 * @param {Notifications#ClientOptions} options - Options to customize client behavior
 *
 * @event stateChanged channelType (registered|unregistered) -- coming from connector, i.e. it's per-connector type!
 * @event transportState Forwarded from Twilsock's stateChanged event.
 * @event message Routed from twilsock as a notification event.
 */
@validateConstructorTypes(nonEmptyString, [
  pureObject,
  "undefined",
  literal(null),
])
class Client extends EventEmitter {
  private readonly twilsock?: TwilsockClient;
  private readonly connectors: Map<ChannelType, Connector>;

  constructor(token: string, options: ClientOptions = {}) {
    super();

    options.logLevel = options.logLevel ?? "error";
    log.setLevel(options.logLevel);

    const productId = options.productId ?? "notifications";

    const startTwilsock = !options.twilsockClient;

    const twilsock = (options.twilsockClient =
      options.twilsockClient ?? new TwilsockClient(token, productId, options));

    const config = options.notifications ?? {};
    const region = config.region ?? options.region ?? "us1";
    const defaultUrl = `https://ers.${region}.twilio.com/v1/registrations`;
    const registrarUrl = config.ersUrl || defaultUrl;

    this.connectors = new Map<ChannelType, Connector>();

    const platform = Client._detectPlatform();

    this.connectors.set(
      "apn",
      new RegistrarConnector(
        "apn",
        { protocolVersion: 4, productId, platform },
        twilsock,
        registrarUrl
      )
    );
    this.connectors.set(
      "fcm",
      new RegistrarConnector(
        "fcm",
        { protocolVersion: 3, productId, platform },
        twilsock,
        registrarUrl
      )
    );
    this.connectors.set(
      "twilsock",
      new TwilsockConnector(productId, platform, twilsock)
    );

    twilsock.on("stateChanged", (state) => this.emit("transportState", state));

    this._connector("twilsock").on("stateChanged", (type, value, state) =>
      this.emit("stateChanged", type, value, state)
    );
    this._connector("apn").on("stateChanged", (type, value, state) =>
      this.emit("stateChanged", type, value, state)
    );
    this._connector("fcm").on("stateChanged", (type, value, state) =>
      this.emit("stateChanged", type, value, state)
    );

    // Router
    twilsock.on("message", (type, message) =>
      this._routeMessage(type, message)
    );

    this.updateToken(token);

    // Start only if we created twilsock locally,
    // otherwise it's the responsibility of whoever created the Twilsock client.
    if (startTwilsock) {
      twilsock.connect();
      this.twilsock = twilsock;
    }
  }

  public async shutdown(): Promise<void> {
    this.connectors.clear();
    if (this.twilsock) {
      await this.twilsock.disconnect();
    }
  }

  /**
   * Set OS-provided APNS/FCM registration binding for the given channel type. Not used for 'twilsock'.
   *
   * You must call this function once you've received the ID of your device from the underlying OS.
   *
   * @param {ChannelType} channelType Channel type ('apn'/'fcm').
   * @param {string} pushRegistrationId Token received from FCM/APNS system on device.
   */
  @validateTypes(channelTypeRule, nonEmptyString)
  public setPushRegistrationId(
    channelType: ChannelType,
    pushRegistrationId: string
  ): void {
    log.debug(
      `Set ${channelType} push registration id '${pushRegistrationId}'`
    );
    this._connector(channelType).setNotificationId(pushRegistrationId);
  }

  /**
   * Subscribe to a given message type for a given channel type.
   *
   * Creates a subscriptions to receive incoming messages according to message type.
   * Subscription establishes a binding and you will receive a signal when a notification
   * of this type has been received by the library.
   *
   * Subscribed binding is preserved for 1 year, after which time it needs to be re-subscribed.
   * This is the responsibility of the client SDK.
   *
   * @param {ChannelType} channelType Supported are 'twilsock', 'apn' and 'fcm'
   * @param {string} messageType The type of message that you want to receive
   */
  @validateTypes(channelTypeRule, nonEmptyString)
  public subscribe(channelType: ChannelType, messageType: string): void {
    log.debug(
      `Add ${channelType} subscriptions for message type ${messageType}`
    );
    this._connector(channelType).subscribe(messageType);
  }

  /**
   * Unsubscribe from a given message type.
   *
   * Unsubscribing breaks a binding and you will not receive more notifications for this message type.
   * Please note that you have to call commitChanges() and receive a successful result before
   * the subscription is actually removed.
   *
   * @param {ChannelType} channelType Supported are 'twilsock', 'apn' and 'fcm'
   * @param {string} messageType The type of message that you don't want to receive anymore
   */
  @validateTypes(channelTypeRule, nonEmptyString)
  public unsubscribe(channelType: ChannelType, messageType: string): void {
    log.debug(
      `Remove ${channelType} subscriptions for message type ${messageType}`
    );
    this._connector(channelType).unsubscribe(messageType);
  }

  /**
   * Update subscription token. You must update the token when the old one expires.
   *
   * When you receive onTokenWillExpire event from twilsock, call this function with the new refreshed
   * token _after_ you have updated twilsock and other associated objects with the new token.
   *
   * @param {string} token Authentication token for registrations
   */
  @validateTypes(nonEmptyString)
  public updateToken(token: string): void {
    this.connectors.forEach((connector) => connector.updateToken(token));
  }

  /**
   * Commit all collected subscription changes as a batched update. This function tries to reduce
   * number of network calls necessary to update bindings status.
   */
  public async commitChanges(): Promise<void> {
    const promises: Promise<void>[] = [];
    this.connectors.forEach((connector) => {
      if (connector.isActive()) {
        promises.push(connector.commitChanges());
      }
    });
    await Promise.all(promises);
  }

  /**
   * Clear existing registrations directly using provided device token.
   * This is useful to ensure stopped subscriptions without resubscribing.
   *
   * This function goes completely beside the state machine and removes all registrations.
   * Use with caution: if it races with current state machine operations, madness will ensue.
   *
   * @param {ChannelType} channelType Channel type ('apn'/'fcm').
   * @param {string} registrationId Token received from FCM/APNS system on device.
   */
  @validateTypesAsync(channelTypeRule, nonEmptyString)
  public async removeRegistrations(
    channelType: ChannelType,
    registrationId: string
  ): Promise<void> {
    await this._connector(channelType).sendDeviceRemoveRequest(registrationId);
  }

  /**
   * Handle incoming push notification.
   * Client application should call this method when it receives push notifications and pass the received data.
   * @param {Object} message push message
   * @return {PushNotification} A reformatted payload with extracted message type.
   */
  public handlePushNotification(message: any): PushNotification {
    return {
      messageType: message.twi_message_type,
      payload: message.payload,
    };
  }

  /**
   * Routes messages to the external subscribers
   */
  private _routeMessage(type: string | undefined, message: string): void {
    log.debug("Notification message arrived: ", type, message);
    this.emit("message", type, message);
  }

  /**
   * @param {String} type Channel type
   * @throws {Error} Error with description
   */
  private _connector(type: ChannelType): Connector {
    const connector = this.connectors.get(type);
    if (!connector) {
      throw new Error(`Unknown channel type: ${type}`);
    }
    return connector;
  }

  /**
   * Returns platform string limited to max 128 chars
   */
  private static _detectPlatform(): string {
    let platform = "";
    if (typeof navigator !== "undefined") {
      platform = "unknown";
      if (typeof navigator.product !== "undefined") {
        platform = navigator.product;
      }
      if (typeof navigator.userAgent !== "undefined") {
        platform = navigator.userAgent;
      }
    } else {
      platform = "web";
    }

    return platform.substring(0, 128);
  }
}

/**
 * @event Client#message
 * Fired when a new notification message arrives.
 * @param {string} type Message type
 * @param {Object} message Message payload
 */

/**
 * @event Client#stateChanged
 * Fired when the registration state changes.
 * @param {ChannelType} type Type of channel
 * @param {string} status Status of registration (registered/unregistered)
 * @param {Object} state Registration state details
 *                       (token, notificationId, currently subscribed types)
 */

/**
 * @event Client#transportState
 * Fired when the twilsock connection state changes.
 * @param {string} state New transport state. Coming directly from Twilsock#ConnectionState.
 */

/**
 * These options can be passed to Client constructor
 * @typedef {Object} Notifications#ClientOptions
 * @property {String} [logLevel='error'] - The level of logging to enable. Valid options
 *   (from strictest to broadest): ['silent', 'error', 'warn', 'info', 'debug', 'trace']
 */

export { ChannelType, ConnectionState, PushNotification, Client };
