import {
  dstream
} from "./chunk-XNV2T2Z2.mjs";
import {
  ComponentScope,
  createInjector,
  createScope,
  getDefaultInjector,
  molecule,
  moleculeInterface,
  onMount,
  onUnmount,
  resetDefaultInjector,
  use
} from "./chunk-PT6O5G7K.mjs";

// src/react/ScopeProvider.tsx
import React3, { useMemo as useMemo2 } from "react";

// src/react/contexts/ScopeContext.tsx
import React from "react";
var ScopeContext = React.createContext([]);
ScopeContext.displayName = "BunshiMoleculeScopeContext";

// src/react/useScopes.tsx
import { useContext as useContext2, useEffect, useMemo, useRef, useState } from "react";

// src/react/internal/flattenTuples.tsx
function flattenTuples(tuples) {
  return tuples.flatMap((t) => t);
}

// src/react/useInjector.tsx
import { useContext } from "react";

// src/react/contexts/InjectorContext.tsx
import React2 from "react";
var InjectorContext = React2.createContext(() => getDefaultInjector());
InjectorContext.displayName = "BunshiMoleculeInjectorContext";

// src/react/useInjector.tsx
function useInjector() {
  return useContext(InjectorContext)();
}

// src/react/useScopes.tsx
function useScopes(options) {
  const inputTuples = useScopeTuplesRaw(options);
  const injector = useInjector();
  const flattened = flattenTuples(inputTuples);
  const sub = useMemo(() => {
    const innerSub = injector.createSubscription();
    innerSub.expand(inputTuples);
    return innerSub;
  }, [injector, ...flattened]);
  const [tuples, setTuples] = useState(sub.tuples);
  useEffect(() => {
    const subbedTuples = sub.start();
    setTuples(subbedTuples);
    return () => {
      sub.stop();
    };
  }, [sub, setTuples]);
  return tuples;
}
function useScopeTuplesRaw(options) {
  const parentScopes = useContext2(ScopeContext);
  const generatedValue = useMemo(
    () => new Error("Do not use this scope value. It is a placeholder only."),
    []
  );
  const componentScopeTuple = useRef([ComponentScope, generatedValue]).current;
  const inputTuples = (() => {
    if (!options)
      return [...parentScopes, componentScopeTuple];
    if (options.withUniqueScope) {
      return dstream(
        dstream(parentScopes, [
          options.withUniqueScope,
          generatedValue
        ]),
        componentScopeTuple
      );
    }
    if (options.withScope) {
      return dstream(
        dstream(parentScopes, options.withScope),
        componentScopeTuple
      );
    }
    if (options.exclusiveScope) {
      return [options.exclusiveScope, componentScopeTuple];
    }
    return [...parentScopes, componentScopeTuple];
  })();
  return inputTuples;
}

// src/react/ScopeProvider.tsx
function ScopeProvider(props) {
  const { value, scope, uniqueValue } = props;
  let options;
  if (uniqueValue) {
    options = {
      withUniqueScope: scope
    };
  } else {
    options = {
      withScope: [scope, value]
    };
  }
  const simpleDownstreamScopes = useScopes(options);
  const downstreamScopes = useMemo2(
    () => simpleDownstreamScopes.filter(([scope2]) => scope2 !== ComponentScope),
    [simpleDownstreamScopes, scope]
  );
  return React3.createElement(
    ScopeContext.Provider,
    { value: downstreamScopes },
    props.children
  );
}

// src/react/useMolecule.tsx
import { useEffect as useEffect2, useMemo as useMemo3, useState as useState2 } from "react";
function useMolecule(mol, options) {
  const injector = useInjector();
  const inputTuples = useScopeTuplesRaw(options);
  const [value, handle] = useMemo3(() => {
    return injector.useLazily(mol, ...inputTuples);
  }, [
    mol,
    injector,
    /**
     * Tuple flattening prevents re-renders unless the number of
     */
    ...flattenTuples(inputTuples)
  ]);
  const [mutableValue, setMutableValue] = useState2(() => value);
  useEffect2(() => {
    const subbedValue = handle.start();
    setMutableValue(() => subbedValue);
    return () => {
      handle.stop();
    };
  }, [handle]);
  return mutableValue;
}

// src/react/InjectorProvider.tsx
var InjectorProvider = InjectorContext.Provider;
export {
  ComponentScope,
  InjectorProvider,
  ScopeProvider,
  createInjector,
  createScope,
  getDefaultInjector,
  molecule,
  moleculeInterface,
  onMount,
  onUnmount,
  resetDefaultInjector,
  use,
  useInjector,
  useMolecule,
  useScopes
};
